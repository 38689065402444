<template>
  <div
    class="input input_visible_label"
    :class="{ 'input_error': !!errorMessage }"
  >
    <label
      :for="name"
      class="input__label"
      :class="labelClass"
    >
      {{ labelText }}
    </label>
    <div class="input__error" v-if="errorMessage">
      {{ errorMessage }}
    </div>
    <div
      class="input__password-wrapper"
      :class="{ 'public': showPassword }"
    >
      <input
        :name="name"
        :id="name"
        class="input__field input__field_password"
        :class="inputClass"
        :type="showPassword ? 'text' : 'password'"
        :value="inputValue"
        :placeholder="placeholder"
        @input="handleChange"
        @blur="handleBlur"
      />
      <a
        v-if="restoreLink"
        :href="restoreLink"
        class="input__link input__link_restore-password"
      >
        Забыли?
      </a>
      <a
        v-if="isShowPassword"
        href="javascript:void(0);"
        class="input__link-password-icon"
        @click="showPassword = !showPassword"
      ><!-- --></a>
    </div>
  </div>
</template>

<script>
import { useField } from 'vee-validate'

export default {
  name: 'InputPassword',
  props: {
    value: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      required: true
    },
    labelText: {
      type: String,
      required: true
    },
    labelClass: {
      type: String,
      default: ''
    },
    inputClass: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    restoreLink: {
      type: String,
      default: ''
    },
    isShowPassword: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      showPassword: false
    }
  },
  setup (props) {
    const {
      value: inputValue,
      errorMessage,
      handleBlur,
      handleChange,
      meta
    } = useField(props.name, undefined, {
      initialValue: props.value
    })

    return {
      handleChange,
      handleBlur,
      errorMessage,
      inputValue,
      meta
    }
  }
}
</script>

<style lang="scss" scoped>
.input__password-wrapper {

  .input__link-password-icon {
    position: absolute;
    width: 20px;
    height: 20px;
    top: 50%;
    right: 20px;
    margin-top: -10px;
    background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz48c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgdmlld0JveD0iMCAwIDE3Ljc0IDciPjxwYXRoIGZpbGw9IiMwMDAwMDAiIGQ9Ik04Ljg3LDYuMjVDMi42NSw2LjI1LDAuMSwxLjkzLDAsMS43NWwxLjczLTFjMC4wOCwwLjE0LDIuMTEsMy41LDcuMTMsMy41YzUuMDgsMCw3LjExLTMuNDYsNy4xMy0zLjVsMS43NCwwLjk5IEMxNy42MywxLjkzLDE1LjA5LDYuMjUsOC44Nyw2LjI1eiIvPjwvc3ZnPg==') 50% 50% no-repeat;
    background-size: contain;
    z-index: 2;
  }
  .input__link_restore-password + .input__link-password-icon {
    right: 90px;
  }
  &.public {

    .input__field_password {
      color: #000000;
    }
    .input__link-password-icon {
      background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz48c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgdmlld0JveD0iMCAwIDE3Ljc0IDciPjxwYXRoIGZpbGw9IiMwMDAwMDAiIGQ9Ik0xNy43NCw0LjVMMTYsNS41Yy0wLjA3LTAuMTItMS40My0yLjM3LTQuNjctMy4yYzAuMzMsMC40OCwwLjUzLDEuMDcsMC41MywxLjdjMCwxLjY2LTEuMzQsMy0zLDNzLTMtMS4zNC0zLTMgYzAtMC42MywwLjItMS4yMiwwLjUzLTEuN0MzLjE3LDMuMTMsMS44LDUuMzgsMS43Myw1LjVMMCw0LjVDMC4xLDQuMzIsMi42NSwwLDguODcsMEMxNS4wOSwwLDE3LjYzLDQuMzIsMTcuNzQsNC41eiIvPjwvc3ZnPg==');
    }
  }
}
@media (max-width: 991.98px) {
  .input__password-wrapper {

    .input__link_restore-password {
      font-size: 1.3rem;
      line-height: 1;
      right: 1.5rem;
    }
    .input__link-password-icon {
      width: 2rem;
      height: 2rem;
      right: 1.5rem;
      margin-top: -1rem;
    }
    .input__link_restore-password + .input__link-password-icon {
      right: 8.5rem;
    }
  }
}
</style>
